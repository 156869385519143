import {
	Box,
	Button,
	Container,
	Grid,
	Hidden,
	LinearProgress,
	MobileStepper,
	Paper,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs} from "@variocube/app-ui";
import {DateTime} from "luxon";
import * as qs from "qs";
import {createElement, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ApiError} from "../../Api";
import {CrumbLink} from "../../components/CrumbLink";
import {DateComponent, Display} from "../../components/DateComponent";
import {ErrorDialog} from "../../components/ErrorDialog";
import {getItem, listItems} from "../../data/items";
import {itemsPaging} from "../../data/pagings";
import {createRental, createRentalRequest, verifyItemUserAccess} from "../../data/rentals";
import {getTenant} from "../../data/tenants";
import {
	CostCenter,
	CreateRentalBorrower,
	CreateRentalRequest,
	Item,
	RentalAlternativeTimeframe,
	RentalAvailabilityCheckResponse,
	Tenant,
} from "../../data/types";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {tenantUserStore} from "../../store/TenantUserStore";
import {KeyboardArrowLeftIcon, KeyboardArrowRightIcon} from "../../theme";
import {luxonDate} from "../../tools";
import {CreateWizardCostCenter} from "./CreateWizardCostCenter";
import {CreateWizardDateRange} from "./CreateWizardDateRange";
import {CreateWizardItemSelector} from "./CreateWizardItemSelector";
import {CreateWizardSummary} from "./CreateWizardSummary";

enum WizardStep {
	DateRange,
	Item,
	Borrower,
	Summary,
}

const steps = 4;

export function RentalCreateByBorrowerWizard() {
	const {t} = useLocalization();
	const navigate = useNavigate();

	const [item, setItem] = useState<Item>();
	const [itemAccess, setItemAccess] = useState<boolean | undefined>();
	const [tenant, setTenant] = useState<Tenant>();
	const [rentalRequest, setRentalRequest] = useState<CreateRentalRequest>();
	const [availability, setAvailability] = useState<RentalAvailabilityCheckResponse>();
	const [activeStep, setActiveStep] = useState(0);
	const [canCreateRental, setCanCreateRental] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState<ApiError>();

	const handleItemSelection = (item?: Item) => {
		const user = tenantUserStore.user;
		if (rentalRequest && user) {
			setItem(item);
			if (item) {
				setRentalRequest({
					...rentalRequest,
					itemUuid: item.uuid,
				});
				verifyItemUserAccess(tenantUserStore.getTenantId(), {itemUuid: item.uuid, borrowerUuid: user.uuid})
					.then(({blacklisted}) => {
						setItemAccess(!blacklisted);
					});
			}
		}
	};

	const initForm = useCallback(async () => {
		const parsed = qs.parse(location.search, {ignoreQueryPrefix: true});
		try {
			const items = await listItems(tenantUserStore.getTenantId(), itemsPaging);
			setCanCreateRental(items.content.length > 0);
			let item;
			if (parsed.itemUuid && typeof parsed.itemUuid == "string") {
				item = await getItem(tenantUserStore.getTenantId(), parsed.itemUuid);
			}

			let from = DateTime.local().startOf("day").plus({hours: 8});
			let until = DateTime.local().startOf("day").plus({hours: 22});
			let timezone = "";
			if (parsed.from) from = luxonDate(parsed.from);
			if (parsed.until) until = luxonDate(parsed.until);

			let borrower: CreateRentalBorrower = {
				name: "",
				email: "",
				phone: "",
				accessKey: "",
				rentalEndsAfterReturn: tenantUserStore.tenant ? tenantUserStore.tenant.rentalEndsAfterReturn : true,
				notifyOnCreation: true,
			};
			console.log(borrower.rentalEndsAfterReturn);
			const user = tenantUserStore.user;
			if (user) {
				borrower = {
					...borrower,
					name: [user.firstName, user.lastName].filter(t => t).join(" ")
						?? t("rentals.borrower.unknownBorrower"),
					email: user.email,
					phone: null,
				};
			}

			let rentalRequest: CreateRentalRequest = {
				from,
				until,
				timezone,
				borrower,
				borrowerUuid: (user) ? user.uuid : null,
				itemUuid: (item) ? item.uuid : "",
				foreignId: "",
				serviceItem: null,
				costCenterUuid: null,
			};

			setRentalRequest(rentalRequest);
			if (item) {
				setItem(item);
				if (user) {
					verifyItemUserAccess(tenantUserStore.getTenantId(), {itemUuid: item.uuid, borrowerUuid: user.uuid})
						.then(({blacklisted}) => {
							setItemAccess(!blacklisted);
						});
				}
			}
		} catch (error) {
			setError(error as any);
		}
	}, []);

	useEffect(() => {
		initForm().then();
		getTenant(tenantUserStore.getTenantId())
			.then(setTenant);
	}, []);

	const handleDateTimeChange = (from: DateTime, until: DateTime) => {
		if (rentalRequest) {
			setRentalRequest({
				...rentalRequest,
				from,
				until,
			});
		}
	};

	const handleAvailabilityChange = (availability?: RentalAvailabilityCheckResponse) => {
		setAvailability(availability);
	};

	const handleTimeframeChange = (timeframe: RentalAlternativeTimeframe) => {
		if (rentalRequest) {
			setRentalRequest({
				...rentalRequest,
				from: timeframe.from,
				until: timeframe.until,
			});
		}
	};

	const handleCostCenterChange = (costCenter: CostCenter) => {
		if (rentalRequest) {
			setRentalRequest({
				...rentalRequest,
				costCenterUuid: costCenter.uuid,
			});
		}
	};

	const generateStepLabel = (activeStep: number) => {
		switch (activeStep) {
			case WizardStep.Item:
				return t("items.singular");
			case WizardStep.DateRange:
				return t("rentals.create.dateRange");
			case WizardStep.Borrower:
				return t("rentals.borrower.label");
			case WizardStep.Summary:
				return t("rentals.create.summary");
		}
		throw new Error(`Invalid step ${activeStep}`);
	};

	const allowNext = () => {
		if (rentalRequest) {
			if (activeStep >= steps - 1) {
				return false;
			}
			switch (activeStep) {
				case WizardStep.Item:
					return item != undefined;
				case WizardStep.DateRange:
					return item == undefined || (rentalRequest.from < rentalRequest.until);
				case WizardStep.Borrower:
					const tenant = tenantUserStore.tenant;
					return (tenant && tenant.enabledCostCenter)
						? rentalRequest.costCenterUuid != null && rentalRequest.borrowerUuid != null
						: true;
				case WizardStep.Summary:
					return true;
			}
		}
		throw new Error(`Invalid step ${activeStep}`);
	};

	const step = (forward: boolean) => {
		const stepAmount = (tenant && !tenant.enabledCostCenter && activeStep === WizardStep.Summary) ? 2 : 1;
		if (forward) {
			setActiveStep(activeStep + stepAmount);
		} else {
			let step = activeStep - stepAmount;
			step = step < 0 ? 0 : step;
			setActiveStep(step);
		}
	};

	const create = () => {
		if (rentalRequest) {
			setSubmitting(true);
			if (itemAccess) {
				createRental(tenantUserStore.getTenantId(), rentalRequest)
					.then(rental => navigate(`/rentals/${rental.uuid}`))
					.catch(setError);
			} else {
				createRentalRequest(tenantUserStore.getTenantId(), rentalRequest)
					.then(request => navigate(`/requests/${request.uuid}`))
					.catch(setError);
			}
		}
	};

	return (
		<RentalAppContainer title={t("rentals.create.title")}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<CrumbLink href="/rentals">{t("rentals.plural")}</CrumbLink>
						<BreadcrumbItem>{t("rentals.create.title")}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
					<Typography variant="h4">{t("rentals.create.title")}</Typography>
				</Grid>
				<Grid item xs={12}>
					{rentalRequest && (
						<Paper>
							<Hidden smDown>
								<Box pt={3} />
								<Stepper activeStep={activeStep} alternativeLabel>
									<Step key="dateRange">
										<StepLabel>
											<div>
												<label>{t("rentals.create.dateRange")}</label>
											</div>
											{activeStep > 1 && (
												<div style={{fontWeight: "lighter"}}>
													<small>
														<DateComponent
															date={rentalRequest.from}
															display={Display.Timestamp}
														/>
														{rentalRequest.until.hasSame(rentalRequest.from, "day")
															? "-"
															: <br />}
														<DateComponent
															date={rentalRequest.until}
															display={rentalRequest.until.hasSame(
																	rentalRequest.from,
																	"day",
																)
																? Display.Time
																: Display.Timestamp}
														/>
													</small>
												</div>
											)}
										</StepLabel>
									</Step>

									<Step key="item">
										<StepLabel>
											<div>
												<label>{t("items.singular")}</label>
											</div>
											{(item && activeStep > 0) && (
												<div style={{fontWeight: "lighter"}}>
													<small>{item.name}</small>
												</div>
											)}
										</StepLabel>
									</Step>

									<Step key="borrower">
										<StepLabel>
											<div>
												<label>{t("rentals.borrower.label")}</label>
											</div>
											{(rentalRequest.borrower.name && activeStep > 2) && (
												<div style={{fontWeight: "lighter"}}>
													<small>{rentalRequest.borrower.name}</small>
												</div>
											)}
										</StepLabel>
									</Step>
									<Step key="summary">
										<StepLabel>{t("rentals.create.summary")}</StepLabel>
									</Step>
								</Stepper>
							</Hidden>
							<Hidden mdUp>
								<Box p={2}>
									<Typography align="center">{generateStepLabel(activeStep)}</Typography>
								</Box>
							</Hidden>
							<Container maxWidth="md" style={{minHeight: 450}}>
								<Box p={2} />
								{WizardStep.Item === activeStep && (
									<CreateWizardItemSelector
										canCreateRental={canCreateRental}
										item={item}
										from={rentalRequest.from}
										until={rentalRequest.until}
										onItemSelect={handleItemSelection}
									/>
								)}
								{WizardStep.DateRange === activeStep
									&& (
										<CreateWizardDateRange
											item={item as any}
											from={rentalRequest.from}
											until={rentalRequest.until}
											onDateTimeChange={handleDateTimeChange}
											onAvailabilityChange={handleAvailabilityChange}
											onAlternativeItemSelect={handleItemSelection}
											onAlternativeTimeframeChange={handleTimeframeChange}
										/>
									)}
								{WizardStep.Borrower === activeStep && (
									<CreateWizardCostCenter onCostCenterChange={handleCostCenterChange} />
								)}
								{WizardStep.Summary === activeStep && (item && itemAccess != undefined) && (
									<CreateWizardSummary
										item={item}
										rentalRequest={rentalRequest}
										itemAccess={itemAccess}
									/>
								)}
								<Box p={4} />
							</Container>
							<MobileStepper
								steps={steps}
								position="static"
								variant="text"
								activeStep={activeStep}
								nextButton={activeStep != steps - 1
									? (
										<Button size="small" disabled={!allowNext()} onClick={() => step(true)}>
											{t("actions.next")}
											<KeyboardArrowRightIcon />
										</Button>
									)
									: (
										<Button
											size="small"
											variant="contained"
											color="primary"
											onClick={() => create()}
											disabled={submitting}
										>
											{t("actions.create")}
											<KeyboardArrowRightIcon />
										</Button>
									)}
								backButton={
									<Button
										size="small"
										disabled={activeStep == 0 || submitting}
										onClick={() => step(false)}
									>
										<KeyboardArrowLeftIcon />
										{t("actions.back")}
									</Button>
								}
							/>
							{submitting && <LinearProgress variant="indeterminate" />}
						</Paper>
					)}
				</Grid>
			</Grid>
			{error && <ErrorDialog error={error} onClose={() => setError(undefined)} />}
		</RentalAppContainer>
	);
}
